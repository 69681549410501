import React from 'react';
import Helmet from "react-helmet";
import favicon16 from "../../images/favicon/HereThere_Favicon_16.png";
import favicon32 from "../../images/favicon/HereThere_Favicon_32.png";
import favicon48 from "../../images/favicon/HereThere_Favicon_48.png";

const Header = props => {
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
      <title>here-there audio archive</title>
      <link rel="icon" type="image/png" href={favicon48} sizes="48x48" />
      <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
      <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-VXN3KY1730"></script>
      <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-VXN3KY1730');
      `}
      </script>
    </Helmet>
  )
}

export default Header;