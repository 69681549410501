import * as React from "react"
import { Link } from "gatsby"
import "./Nav.scss";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavOpen: false
    }
  }

  toggleMobileNav = () => {
    this.setState({
      mobileNavOpen: !this.state.mobileNavOpen
    })
  }

  render () {
    return (
      <>
      {
        // check whether props.navColour is being passed 
        // *** (only passed on Index page to handle colour change interaction from storySlides)
        this.props.navColour ?
        <style>
        {`
          nav a {
            color: ${this.props.navColour};
          }
          .circle {
            border-color: ${this.props.navColour};          
          }
          li a[aria-current=page] .circle, li a:hover .circle {
            background-color: ${this.props.navColour};
          }
        `}
      </style>
      :
      <style>
      {`
        nav a {
          color: #000;
        }
        .circle {
          border-color: #000;          
        }
        li a[aria-current=page] .circle, li a:hover .circle {
          background-color: #000;
        }
      `}
    </style>
      }

      <nav>
        <Link to="/" onClick={this.props.homeLinkListener ? this.props.homeLinkListener : null} state={{ storyIndexFromLibrary: 0 }}>here-there</Link>
        <ul>
          <li>
            <Link to="/library"><div className="circle"></div>library</Link>
          </li>

          <li>
            <Link to="/about"><div className="circle"></div>about</Link>
          </li>
  
          <li>
            <Link to="/participate"><div className="circle"></div>participate</Link>
          </li>
  
          <li>
            <Link to="/guestbook"><div className="circle"></div>guestbook</Link>
          </li>
        </ul>
        <div className={this.state.mobileNavOpen ? "mobile-nav-toggle active" : "mobile-nav-toggle"} onClick={this.toggleMobileNav}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </nav>
      <div className={this.state.mobileNavOpen ? "mobile-nav active wrapper" : "mobile-nav wrapper"}>
        <ul>
          <li>
            <Link to="/library"><div className="circle"></div><h1>library</h1></Link>
          </li>
          <li>
            <Link to="/about"><div className="circle"></div><h1>about</h1></Link>
          </li>
          <li>
            <Link to="/participate"><div className="circle"></div><h1>participate</h1></Link>
          </li>
          <li>
            <Link to="/guestbook"><div className="circle"></div><h1>guestbook</h1></Link>
          </li>
        </ul>
      </div>
      </>
    );
  }
}

export default Nav;